import { post } from './request';

export default {
  postUserJobOffer (data) {
    const leadData = {
      source: data.source,
      referral_url: data.referral_url,
      click_source: data.click_source,
      utm_source: data.utm_source,
      type: 'job',
      locale: data.locale,
      offer_id: null,
      city: data.offer.city.slug,
      feed: data.offer.feedId,
      partner: data.offer.partner.slug,
      title: data.offer.slugTitle,
    };

    return post('/lead', {
      target: 'ofp-api',
      data: leadData,
      silenceErrors: true,
      sendSessionId: true,
    });
  },
};
